import { useMemo, useState, useEffect, useContext } from "react"
import tw from "twin.macro"
import Image from "components/Image"
import Link from "next/link"

import useBreakpoint from "lib/useBreakpoint"
import TileDataContext from "context/TileDataContext"
import { H2, H4, H6 } from "styles/Text"
import SRText from "components/SRText"
import Language from "components/Language"

import { Tile, HeadingWrapper, TileBody } from "components/TileGrid/styles"
import getImageSize from "./getImageSize"

export const GenericTile = ({ idx, ...page }) => {
  const { Name, UID, id } = page
  const [BackgroundImage, setBackgroundImage] = useState(
    page?.Hero?.BackgroundImage || null
  )
  const [page_type, setPageType] = useState(page?.page_type)
  const { isLarge } = useBreakpoint()
  const isMobile = !isLarge
  const { getGenericPage } = useContext(TileDataContext)

  const isFeatureTile = idx % 10 === 0 || idx % 10 === 9
  const [imgWidth, imgHeight] = useMemo(
    () => getImageSize(isFeatureTile, isMobile),
    [isFeatureTile, isMobile]
  )

  useEffect(() => {
    if (BackgroundImage === null) {
      getGenericPage(parseInt(id, 10))
        .then((data) => {
          if (data?.Hero?.BackgroundImage) {
            setBackgroundImage(data?.Hero?.BackgroundImage)
          } else {
            setBackgroundImage(false)
          }
          if (data?.page_type) {
            setPageType(data?.page_type)
          }
        })
        .catch((e) => {
          console.warn(`No info found for experience tile ${id}`, e)
        })
    }
  }, [BackgroundImage, getGenericPage, id])

  return (
    <Tile>
      <Link href={`/${UID}`} passHref>
        <a tw="absolute inset-0 z-30">
          <SRText>
            <Language unwrap>{Name}</Language>
          </SRText>
        </a>
      </Link>
      <TileBody>
        <div>
          <H6 as="div" tw="mb-1 lg:mb-3">
            {page_type?.Name || "Page"}
          </H6>
          {isFeatureTile && !isMobile ? (
            <H2 as="h3">
              <HeadingWrapper wide>
                <Language unwrap>{Name}</Language>
              </HeadingWrapper>
            </H2>
          ) : (
            <H4 as="h3">
              <HeadingWrapper>
                <Language unwrap>{Name}</Language>
              </HeadingWrapper>
            </H4>
          )}
        </div>
      </TileBody>
      <div tw="absolute inset-0 bg-black opacity-20 z-10" />
      {BackgroundImage?.url ? (
        <Image
          src={BackgroundImage?.url}
          alt={BackgroundImage?.alternativeText || ""}
          layout="intrinsic"
          width={imgWidth}
          height={imgHeight}
          objectFit="cover"
          aria-hidden="true"
        />
      ) : (
        <div
          style={{
            width: "100%",
            paddingBottom: `${(imgHeight / imgWidth) * 100}%`,
            height: 0,
          }}
          aria-hidden="true"
        />
      )}
    </Tile>
  )
}

export default GenericTile
