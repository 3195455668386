import { ImageTilesGrid } from "./styles"
import ExperienceTile from "./tiles/Experience"
import GenericTile from "./tiles/Generic"

export enum TileTypes {
  EXPERIENCE = "experience",
  GENERIC = "generic",
  STORY = "story",
}

const Tile = ({ type, idx, ...props }) => {
  switch (type) {
    case TileTypes.EXPERIENCE:
      return <ExperienceTile idx={idx} {...props} />
    case TileTypes.GENERIC:
    default:
      return <GenericTile idx={idx} {...props} />
  }
}

export const TileGrid = ({ tiles, type = null, ...props }) => (
  <ImageTilesGrid {...props}>
    {tiles?.map((tile, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <Tile
        key={JSON.stringify(tile)}
        idx={idx}
        {...tile}
        type={tile?.type || type}
      />
    ))}
  </ImageTilesGrid>
)

export default TileGrid
