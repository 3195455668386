import { useState, useContext, useEffect, useMemo } from "react"
import tw from "twin.macro"
import Image from "components/Image"
import Link from "next/link"

import useBreakpoint from "lib/useBreakpoint"
import useMuwayiTheme from "lib/useMuwayiTheme"
import TripContext from "context/TripContext"
import TileDataContext from "context/TileDataContext"

import { H2, H4, H6 } from "styles/Text"
import HeartIcon from "icons/Heart"
import SRText from "components/SRText"
import Language from "components/Language"

import {
  Tile,
  HeadingWrapper,
  TileBody,
  FavouriteButton,
} from "components/TileGrid/styles"
import getImageSize from "./getImageSize"

export const ExperienceTile = ({ idx, ...experience }) => {
  const { Name, id, UID } = experience
  const [BackgroundImage, setBackgroundImage] = useState(
    experience?.Hero?.BackgroundImage || null
  )
  const { isLarge } = useBreakpoint()
  const isMobile = !isLarge
  const {
    addExperience,
    experiences: ctxExperiences,
    removeExperience,
    ready: experiencesReady,
  } = useContext(TripContext)
  const { getExperiencePage } = useContext(TileDataContext)
  const { hideAddToTrip } = useMuwayiTheme()

  useEffect(() => {
    if (BackgroundImage === null) {
      getExperiencePage(id)
        .then((data) => {
          if (!data?.Hero?.BackgroundImage) {
            throw "No Image"
          }
          setBackgroundImage(data?.Hero?.BackgroundImage || false)
        })
        .catch((e) => {
          console.warn(`No image found for experience tile ${id}`, e)
        })
    }
  }, [BackgroundImage, getExperiencePage, id])

  const isFavourite = useMemo(
    () =>
      ctxExperiences
        .reduce((acc, day) => [...acc, ...day])
        .map((xp) => xp.id)
        .indexOf(id) > -1,
    [ctxExperiences, id]
  )

  const isFeatureTile = idx % 10 === 0 || idx % 10 === 9
  const [imgWidth, imgHeight] = useMemo(
    () => getImageSize(isFeatureTile, isMobile),
    [isFeatureTile, isMobile]
  )

  return (
    <Tile>
      <Link href={`/experience/${UID}`} passHref>
        <a tw="absolute inset-0 z-30">
          <SRText>
            <Language unwrap>{Name}</Language>
          </SRText>
        </a>
      </Link>
      <TileBody>
        <div>
          <H6 as="div" aria-hidden="true" tw="mb-1 lg:mb-3">
            Experience
          </H6>
          {isFeatureTile && !isMobile ? (
            <H2 as="h3">
              <HeadingWrapper wide>
                <Language unwrap>{Name}</Language>
              </HeadingWrapper>
            </H2>
          ) : (
            <H4 as="h3">
              <HeadingWrapper>
                <Language unwrap>{Name}</Language>
              </HeadingWrapper>
            </H4>
          )}
        </div>
      </TileBody>
      {experiencesReady && !hideAddToTrip && (
        <FavouriteButton
          onClick={() => {
            if (isFavourite) {
              removeExperience(id)
            } else {
              addExperience({
                ...experience,
                Hero: {
                  BackgroundImage,
                },
              })
            }
          }}
        >
          <SRText>
            <Language unwrap>
              {isFavourite
                ? `Add ${Name} to favourites`
                : `Remove ${Name} from favourites`}
            </Language>
          </SRText>
          <HeartIcon
            aria-hidden="true"
            tw="block relative z-10"
            css={isFavourite && tw`text-primary fill-current`}
          />
        </FavouriteButton>
      )}
      <div tw="absolute inset-0 bg-black opacity-20 z-10" />
      {BackgroundImage?.url ? (
        <Image
          src={BackgroundImage?.url}
          alt={BackgroundImage?.alternativeText || ""}
          layout="intrinsic"
          width={imgWidth}
          height={imgHeight}
          objectFit="cover"
          aria-hidden="true"
        />
      ) : (
        <div
          style={{
            width: "100%",
            paddingBottom: `${(imgHeight / imgWidth) * 100}%`,
            height: 0,
          }}
          aria-hidden="true"
        />
      )}
    </Tile>
  )
}

export default ExperienceTile
