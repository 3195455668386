export default function getImageSize(
  isFeatureTile: boolean,
  isMobile: boolean
) {
  let width = 375
  let height = 510
  if (isFeatureTile && !isMobile) {
    width = 590
    height = 420
  } else if (isFeatureTile && isMobile) {
    width = 725
    height = 473
  } else if (!isFeatureTile && !isMobile) {
    width = 285
    height = 200
  }
  return [width, height]
}
