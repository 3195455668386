import { keyframes } from "styled-components"
import { colors, breakpoint, breakpoints } from "styles/Variables"
import tw, { styled } from "twin.macro"

export const ImageTilesGrid = styled.div`
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  grid-gap: 10px;
  grid-auto-flow: dense;
  ${breakpoint.md`
    grid-template: 1fr / 1fr 1fr 1fr 1fr;
  `}
  ${breakpoint.lg`
    grid-gap: 20px;
  `}
`

export const Tile = styled.article`
  background-color: ${colors["dark-grey"]};
  color: ${colors.white};
  position: relative;
  display: flex;
  &:nth-child(10n + 1),
  &:nth-child(10n + 10) {
    grid-column-end: span 2;
    grid-row-end: span 2;
  }
  ${breakpoint.md`
    &:nth-child(10n + 10) {
      grid-column-start: 3;
    }
    &:nth-child(10n + 8) {
      grid-column: 1;
    }
    &:nth-child(10n + 9) {
      grid-column: 2;
    }
  `}
`

export const HeadingWrapper = styled.div<{
  wide?: boolean
}>`
  width: ${({ wide }) => (wide ? "9.6em" : "7.6em")};
  max-width: 100%;
  hyphens: auto;
`

export const TileBody = tw.div`
p-3 lg:p-5 absolute inset-0 flex flex-col justify-end z-20 h-full
`

const fadeIn = keyframes`
from {
  opacity: 0;
}
to: {
  opacity: 1;
}
`

export const FavouriteButton = styled.button.attrs(() => ({
  type: "button",
}))`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 10px 10px 13px;
  z-index: 30;
  cursor: pointer;
  transition: color 0.3s;
  animation: ${fadeIn} 0.4s;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: radial-gradient(black, transparent 50%);
    opacity: 0;
    transition: opacity 0.3s;
  }
  @media (min-width: ${breakpoints.lg}) {
    &:hover {
      // color: ${colors.primary};
      &::after {
        opacity: 1;
      }
    }
  }
`
